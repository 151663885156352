import React from "react"
import { graphql } from "gatsby"

import Layout from "~/components/layout"
import SEO from "~/components/seo"
// import CategoryList from "~/components/category-list"
import CompanyTypeList from "~/components/company-type-list"
import PageHeading from "~/components/styled/page-heading"

const IndexPage = ({ data: { allStrapiCompanyType } }) => {
  const CompanyTypes = allStrapiCompanyType.edges
  const seo = { title: "业务类形" }
  return (
    <Layout>
      <SEO seo={seo} />
      <PageHeading>业务类形</PageHeading>
      <CompanyTypeList companytypes={CompanyTypes} />
    </Layout>
  )
}

export const query = graphql`
  query CompanyTypeQuery {
    allStrapiCompanyType {
      edges {
        node {
          id
          name
          slug
        }
      }
    }
  }
`

export default IndexPage
