import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"

import Image from "~/components/image"
import Card from "~/components/styled/card"

const TypeList = ({ companytypes }) => (
  <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
    {companytypes.map(({ node }) => {
      return (
        <Card key={node.id}>
          <Link to={`/company-types/${node.slug}`}>
            <p className="px-4 py-6">{node.name}</p>
          </Link>
        </Card>
      )
    })}
  </div>
)

TypeList.propTypes = {
  companytypes: PropTypes.array,
}

export default TypeList
